import { ActionTree } from "vuex";
import store, { State } from ".";
let timer: any = null;

const actions: ActionTree<State, any> = {
  setFirstStatement({ commit }, statement: number) {
    if (typeof statement !== "number") return;
    if (statement > store.getters.statements.length || statement < 1) return;

    commit("setFirstStatementDefaultIndex", statement - 1);
  },
  setStatementChoice(
    { state, commit },
    payload: {
      choice: "agree" | "neither" | "disagree" | "skip";
      statement: number;
      originalStatementId: number;
      shootout: boolean;
    },
  ) {
    const userChoices = payload.shootout
      ? state.user.shootoutChoices
      : state.user.choices;

    // if (payload.statement > (userChoices.length + 1)) return

    userChoices[payload.statement - 1] = {
      choice: payload.choice,
      originalStatementId: payload.originalStatementId,
    };
    commit(
      payload.shootout ? "setUserShootoutChoices" : "setUserChoices",
      userChoices,
    );
  },
  startTimer({ commit }) {
    if (timer === null) {
      timer = setInterval(() => {
        commit("increaseTimeSpendMakingChoices");
      }, 100);
    }
  },
  stopTimer() {
    clearInterval(timer);
    timer = null;
  },
  setPriorityStatements({ commit }, priorityStatements: number[] | false) {
    commit("setPriorityStatements", priorityStatements);
  },
  removeFromPriorityStatements({ state, commit }, statementId: number) {
    const priorityStatements = state.user.priorityStatements;

    if (!priorityStatements) return;
    if (priorityStatements.indexOf(statementId) === -1) return;

    priorityStatements.splice(priorityStatements.indexOf(statementId), 1);

    commit("setPriorityStatements", priorityStatements);
  },
  setSelectedParties({ commit }, selectedParties: number[] | false) {
    commit("setSelectedParties", selectedParties);
  },
  skippedStart({ commit }) {
    commit("skippedStart");
  },
  seenPrivacyStatement({ commit }) {
    commit("seenPrivacyStatement");
  },
  setUserAnalytics(
    { commit },
    analytics: { sendAllChoices: boolean; sendForAnalysis: boolean },
  ) {
    commit("setUserAnalytics", analytics);
  },
  setUserToken({ commit }, token: string) {
    commit("setUserToken", token);
  },
  setDirection({ state, commit }, routes: { new: any; old: any }) {
    let direction: "left" | "right" = state.direction;

    if (routes.new.name === "start") {
      direction = "right";
    } else if (routes.new.name === "statement") {
      switch (routes.old.name) {
        case "start":
          direction = "left";
          break;
        case "select-statements":
        case "select-parties":
          direction = "right";
          break;
        case "statement":
          if (
            JSON.parse(routes.old.params.id) > JSON.parse(routes.new.params.id)
          ) {
            direction = "right";
          } else {
            direction = "left";
          }
          break;
        default:
          direction = "left";
          break;
      }
    } else if (routes.new.name === "shootout-statement") {
      switch (routes.old.name) {
        case "results":
          if (JSON.parse(routes.new.params.id) === 1) {
            direction = "left";
          } else if (
            JSON.parse(routes.new.params.id) ===
            store.getters.shootoutStatements().length
          ) {
            direction = "right";
          }
          break;
        case "shootout-statement":
          if (
            JSON.parse(routes.old.params.id) > JSON.parse(routes.new.params.id)
          ) {
            direction = "right";
          } else {
            direction = "left";
          }
          break;
        default:
          direction = "left";
          break;
      }
    } else if (routes.new.name === "select-statements") {
      switch (routes.old.name) {
        case "statement":
          direction = "left";
          break;
        case "select-parties":
          direction = "right";
          break;
        default:
          direction = "left";
          break;
      }
    } else if (routes.new.name === "select-parties") {
      switch (routes.old.name) {
        case "select-statements":
          direction = "left";
          break;
        case "select-analytics":
        case "results":
          direction = "right";
          break;
        default:
          direction = "left";
          break;
      }
    } else if (routes.new.name === "select-analytics") {
      switch (routes.old.name) {
        case "select-parties":
          direction = "left";
          break;
        case "results":
          direction = "right";
          break;
        default:
          direction = "left";
          break;
      }
    } else if (
      routes.new.name === "results" ||
      routes.new.name === "choices-overview"
    ) {
      switch (routes.old.name) {
        case "shootout-statement":
          if (JSON.parse(routes.old.params.id) === 1) {
            commit("setShootoutResultsVisibility", false);
            direction = "right";
          } else if (
            JSON.parse(routes.old.params.id) ===
            store.getters.shootoutStatements().length
          ) {
            commit("setShootoutResultsVisibility", true);
            direction = "left";
          }
          break;
        default:
          direction = "left";
          break;
      }
    }

    commit("setDirection", direction);
  },
  setShootoutParties({ commit }, parties: false | [number, number]) {
    if (parties === false) {
      commit("setShootoutResultsVisibility", false);
      commit("setUserShootoutChoices", []);
    }

    commit("setShootoutParties", parties);
  },
  seenAnalyticsQuestion({ commit }) {
    commit("seenAnalyticsQuestion");
  },
  setSavedResults({ commit }, payload: any) {
    commit("setSavedResults", payload);
  },
};

export default actions;
