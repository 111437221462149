import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "privacy__wrapper"
}
const _hoisted_4 = { class: "privacy__header" }
const _hoisted_5 = { class: "privacy__header-title" }
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = ["aria-label", "innerHTML"]
const _hoisted_8 = { class: "modal" }
const _hoisted_9 = { class: "modal__title" }
const _hoisted_10 = ["aria-label", "innerHTML"]
const _hoisted_11 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_nav = _resolveComponent("app-nav")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app", {
      'with-ad': $setup.config.settings.showAd && $setup.route.name !== 'embed',
      'app--white': $setup.route.name !== 'start',
      'app--prevent-scrolling': $setup.preventScrolling,
    }])
  }, [
    ($setup.route.name !== 'embed')
      ? (_openBlock(), _createBlock(_component_app_nav, { key: 0 }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_router_view, {
      class: "app__content",
      onPreventScrolling: _cache[0] || (_cache[0] = ($event: any) => ($setup.preventScrolling = true)),
      onAllowScrolling: _cache[1] || (_cache[1] = ($event: any) => ($setup.preventScrolling = false)),
      onOpenPrivacyModal: _cache[2] || (_cache[2] = ($event: any) => ($setup.privacyModalOpen = true)),
      key: $setup.routeKey
    }, {
      default: _withCtx((slotProps) => [
        _createVNode(_Transition, {
          mode: "out-in",
          name: $setup.contentAnimation
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(slotProps.Component)))
          ]),
          _: 2
        }, 1032, ["name"])
      ]),
      _: 1
    })),
    (
        $setup.config.settings.showAd &&
        $setup.config.settings.desktopAdCode &&
        $setup.route.name !== 'embed'
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "app__ad",
          innerHTML: $setup.config.settings.desktopAdCode
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (
        $setup.config.settings.showAd &&
        $setup.config.settings.mobileAdCode &&
        $setup.route.name !== 'embed'
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "app__ad app__ad--mobile",
          innerHTML: $setup.config.settings.mobileAdCode
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: 
        $setup.config.settings.showAd
          ? 'slide-right-mobile-down-with-ad'
          : 'slide-right-mobile-down'
      
    }, {
      default: _withCtx(() => [
        (
          $setup.store.getters.showPrivacyStatement &&
          $setup.animateOnLoad &&
          $setup.route.name !== 'embed'
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("header", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString($setup.dictionary.app.privacy.title), 1),
                _createElementVNode("button", {
                  class: "privacy__close",
                  "aria-label": $setup.dictionary.app.closeModal,
                  tabindex: "0",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.closePrivacy()))
                }, _toDisplayString($setup.dictionary.app.closeModal), 9, _hoisted_6)
              ]),
              _createElementVNode("div", {
                class: "privacy__text",
                "aria-label": 
            $setup.dictionary.app.accessibility.privacy.text
              ? $setup.dictionary.app.accessibility.privacy.text
              : $setup.dictionary.app.privacy.text
          ,
                innerHTML: $setup.dictionary.app.privacy.text
              }, null, 8, _hoisted_7),
              ($setup.dictionary.app.privacy.modalText)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "privacy__button button button--white",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.privacyModalOpen = true))
                  }, _toDisplayString($setup.dictionary.app.privacy.button), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($setup.privacyModalOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "modal__wrapper",
              ref: "modalWrapper",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($setup.closePrivacyModal && $setup.closePrivacyModal(...args)))
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h3", _hoisted_9, _toDisplayString($setup.dictionary.app.privacy.title), 1),
                _createElementVNode("div", {
                  class: "modal__content text",
                  "aria-label": 
              $setup.dictionary.app.accessibility.privacy.modalText
                ? $setup.dictionary.app.accessibility.privacy.modalText
                : $setup.dictionary.app.privacy.modalText
            ,
                  innerHTML: $setup.dictionary.app.privacy.modalText
                }, null, 8, _hoisted_10),
                _createElementVNode("button", {
                  class: "privacy__close privacy__close--black",
                  ref: "modalClose",
                  "aria-label": $setup.dictionary.app.closeModal,
                  tabindex: "0",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.privacyModalOpen = false))
                }, _toDisplayString($setup.dictionary.app.closeModal), 9, _hoisted_11)
              ])
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}