import store from "@/store";
import GlobalMixins from "./mixins";

import { IStatement } from "./interfaces";
import { RouteRecordRaw, createRouter, createWebHashHistory } from "vue-router";

const { dictionary, slugify, statementTitleToString, config, rawStatements } =
  GlobalMixins.setup();
export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "start",
    component: () => import("@/views/Start.vue"),
    meta: {
      title: dictionary.start.tabTitle,
    },
  },
  {
    path: `/embed/:id/`,
    name: "embed",
    component: () => import("@/views/Embed.vue"),
    meta: {
      title: dictionary.statement.tabTitle,
    },
  },
  {
    path: `/${slugify(dictionary.statement.tabTitle)}/:id/:title`,
    name: "statement",
    component: () => import("@/views/Statement.vue"),
    meta: {
      title: dictionary.statement.tabTitle,
    },
  },
  {
    path: `/${slugify(dictionary.shootoutStatement.tabTitle)}/:id/:title`,
    name: "shootout-statement",
    component: () => import("@/views/Statement.vue"),
    meta: {
      title: dictionary.shootoutStatement.tabTitle,
    },
  },
  {
    path: `/${slugify(dictionary.selectStatements.tabTitle)}`,
    name: "select-statements",
    component: () => import("@/views/SelectStatements.vue"),
    meta: {
      title: dictionary.selectStatements.tabTitle,
    },
  },
  {
    path: `/${slugify(dictionary.selectParties.tabTitle)}`,
    name: "select-parties",
    component: () => import("@/views/SelectParties.vue"),
    meta: {
      title: dictionary.selectParties.tabTitle,
    },
  },
  {
    path: `/${slugify(dictionary.selectAnalytics.tabTitle)}`,
    name: "select-analytics",
    component: () => import("@/views/SelectAnalytics.vue"),
    meta: {
      title: dictionary.selectAnalytics.tabTitle,
    },
  },
  {
    path: `/${slugify(dictionary.results.tabTitle)}`,
    name: "results",
    component: () => import("@/views/Results.vue"),
    meta: {
      title: dictionary.results.tabTitle,
    },
    children: [
      {
        path: `/${slugify(dictionary.results.tabTitle)}/${slugify(dictionary.results.choicesOverview.tabTitle)}`,
        name: "choices-overview",
        component: () => import("@/components/ChoicesOverview.vue"),
        meta: {
          title: dictionary.results.choicesOverview.tabTitle,
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: {
      name: "start",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

const urlParams = (): any => {
  const params: any = {};
  const parser = document.createElement("a");
  parser.href = window.location.href;
  const query = parser.search.substring(1);
  const vars = query.split("&");

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }

  let allEmpty = true;

  Object.keys(params).forEach((key) => {
    if (key !== "" && params[key] !== undefined) allEmpty = false;
  });

  return allEmpty ? false : params;
};

router.beforeEach((to, from, next) => {
  let routeToNavigateTo: any = undefined;
  const firstSetup: boolean =
    store.getters.firstStatementDefaultIndex === false ? true : false;

  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual";
  }

  if (urlParams()) {
    Object.keys(urlParams()).forEach((key) => {
      if (key === slugify(dictionary.statement.tabTitle)) {
        if (
          /^\d+$/.test(urlParams()[key]) &&
          urlParams()[key].length < 4 &&
          (urlParams()[key].length === 1 || urlParams()[key][0] !== "0")
        ) {
          store.dispatch("setFirstStatement", JSON.parse(urlParams()[key]));
        }
      }
    });
  }

  if (
    (to.name === "start" && config.settings.skipStart) ||
    (to.name === "start" &&
      urlParams()[slugify(dictionary.statement.tabTitle)] &&
      firstSetup)
  ) {
    store.dispatch("skippedStart");

    routeToNavigateTo = {
      name: "statement",
      params: {
        id: "1",
        title: slugify(
          statementTitleToString(
            (store.getters.statement(1) as IStatement).title,
          ),
        ),
      },
    };
  } else if (to.name === "statement" || to.name === "shootout-statement") {
    if (config.settings.skipStart && to.name === "statement")
      store.dispatch("skippedStart");
    if (to.name === "statement" && to.params.id === "1" && !from.name)
      store.dispatch("skippedStart");

    if (
      to.name === "shootout-statement" &&
      (store.getters.user.shootoutParties === false ||
        store.getters.user.shootoutParties.length !== 2)
    ) {
      routeToNavigateTo = {
        name: "results",
      };
    } else if (!to.params.title) {
      routeToNavigateTo = {
        name: to.name,
        params: {
          id: to.params.id,
          title: slugify(
            statementTitleToString(
              store.getters.statement(JSON.parse(to.params.id.toString()))
                .title,
            ),
          ),
        },
      };
    } else if (
      to.params.id !== "1" &&
      JSON.parse(to.params.id.toString()) - 1 >
        (to.name === "shootout-statement"
          ? store.getters.user.shootoutChoices.length
          : store.getters.user.choices.length)
    ) {
      routeToNavigateTo = {
        name: to.name === "shootout-statement" ? "results" : "start",
      };
    }
  } else if (
    (to.name === "results" ||
      to.name === "choices-overview" ||
      to.name === "select-statements" ||
      to.name === "select-parties") &&
    store.getters.user.choices.length !== store.getters.statements.length
  ) {
    routeToNavigateTo = {
      name: "start",
    };
  } else if (
    (to.name === "select-statements" &&
      config.settings.maxSelectedStatements === 0) ||
    (to.name === "select-analytics" &&
      (!store.getters.user.selectedParties ||
        store.getters.user.selectedParties.length <
          (config.settings.minimumSelectedParties
            ? config.settings.minimumSelectedParties
            : 3))) ||
    ((to.name === "results" || to.name === "choices-overview") &&
      (!store.getters.user.selectedParties ||
        store.getters.user.selectedParties.length <
          (config.settings.minimumSelectedParties
            ? config.settings.minimumSelectedParties
            : 3)))
  ) {
    routeToNavigateTo = {
      name: "select-parties",
    };
  } else if (
    to.name === "select-analytics" &&
    !store.getters.showAnalyticsQuestion
  ) {
    if (from.name === "results") {
      routeToNavigateTo = {
        name: "select-parties",
      };
    } else {
      routeToNavigateTo = {
        name: "results",
      };
    }
  } else if (
    to.name === "choices-overview" &&
    !config.settings.showStatementOverview
  ) {
    routeToNavigateTo = {
      name: "results",
    };
  } else if (
    to.name === "embed" &&
    (!to.params.id ||
      Number.isNaN(Number.parseInt(to.params.id.toString())) ||
      !JSON.parse(to.params.id.toString()) ||
      rawStatements.length < JSON.parse(to.params.id.toString()))
  ) {
    routeToNavigateTo = {
      name: "start",
    };
  }

  if (
    to.name === "results" &&
    (from.name === "select-analytics" || from.name === "select-parties")
  ) {
    store.dispatch("setShootoutParties", false);
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 150);

  if (to.name === "load-user") {
    routeToNavigateTo = false;
  }

  store.dispatch("setDirection", {
    new: routeToNavigateTo ? routeToNavigateTo : to,
    old: from,
  });

  if (routeToNavigateTo) {
    if (routeToNavigateTo.name === "statement") {
      store.dispatch("startTimer");
    } else {
      store.dispatch("stopTimer");
    }

    next(routeToNavigateTo);
  } else {
    if (to.name === "statement") {
      store.dispatch("startTimer");
    } else {
      store.dispatch("stopTimer");
    }

    next();
  }
});

export default router;
