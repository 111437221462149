import * as Config from "@/data/config.json";
import * as Dictionary from "@/data/dictionary.json";
import {
  IConfig,
  IStatement,
  IResult,
  IParty,
  IPartyStatement,
  IShootoutStatement,
  IDictionary,
} from "./interfaces";
import { State } from "./store";

// import * as Data from '@/data/data.json' as {
//	default: {
//		votematch? : string; // this isnt there ?
//		parties: IParty[];
//		statements: IStatement[];
//		shootoutStatements: IShootoutStatement;
//	}
//};

import CryptedData from "@/data/crypted";
const decrypt = decodeURI(atob(CryptedData));
const Data = { default: JSON.parse(decrypt) } as {
  default: {
    votematch?: string; // this isnt there ?
    parties: IParty[];
    statements: IStatement[];
    shootoutStatements: IShootoutStatement[];
  };
};

import { ref, computed } from "vue";

// warn mixins.ts:36 [Vue warn]: inject() can only be used inside setup() or functional components.
// https://stackoverflow.com/questions/65340740/vue-3-inject-can-only-be-used-inside-setup-or-functional-components

import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "GlobalMixins",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // variables
    const config = (Config as any).default as IConfig;
    const dictionary = (Dictionary as any).default as IDictionary;
    const votematch = Data.default.votematch;
    const parties = Data.default.parties;
    const rawStatements = Data.default.statements;
    const rawShootoutStatements = Data.default.shootoutStatements;
    const partyNameForChoicesOverviewTitle = ref("");
    const updateCounter = ref(0);

    // computed data

    const user = computed(function (): State["user"] {
      return store.getters.user;
    });

    const statements = computed(function (): IStatement[] {
      return store.getters.statements;
    });

    const shootoutStatements = computed(function (): IShootoutStatement[] {
      return store.getters.shootoutStatements();
    });

    const amountSkipped = computed(function (): number {
      const filledInStatements = user.value.choices.reduce(
        (total: number, choice: any) => {
          return total + (choice.choice !== "skip" ? 1 : 0);
        },
        0,
      );

      return filledInStatements;
    });

    const maxScore = computed(function (): number {
      updateCounter;

      let maxScore = 0;

      if (
        user.value.priorityStatements &&
        user.value.priorityStatements.length
      ) {
        maxScore += user.value.priorityStatements.length;
      }

      user.value.choices.forEach((statement) => {
        if (statement.choice !== "skip") maxScore++;
      });

      return maxScore;
    });
    const isEmbedded = computed(function (): boolean {
      return window.location !== window.parent.location;
    });

    function results(onlySelected: boolean): IResult[] {
      if (
        route.name !== "select-analytics" &&
        route.name !== "results" &&
        route.name !== "choices-overview"
      )
        return [];

      let results: IResult[] = [];
      let savedResults = store.getters.savedResults;

      if (savedResults) {
        if (
          JSON.stringify(user.value.choices) === savedResults.userChoicesString
        ) {
          results = savedResults.results;
        } else {
          store.dispatch("setSavedResults", false);

          savedResults = false;
        }
      }

      if (!results.length) {
        parties
          .filter((party: any) => {
            return party.participates;
          })
          .forEach((party: any) => {
            let percentage = 0;

            if (party.statements && party.statements.length) {
              Object.values(statements.value).forEach((statement) => {
                const userChoice = user.value.choices.filter((uStatement) => {
                  return uStatement.originalStatementId === statement.id;
                })[0].choice;
                const partyPosition = (
                  party.statements as IPartyStatement[]
                ).filter((pStatement) => {
                  return pStatement.id === statement.id;
                })[0].position;
                const isDoubled = user.value.priorityStatements
                  ? user.value.priorityStatements.indexOf(statement.id) !== -1
                  : false;

                if (userChoice !== "skip" && userChoice === partyPosition) {
                  percentage += ((isDoubled ? 2 : 1) / maxScore.value) * 100;
                }
              });
            }

            results.push({
              party,
              percentage: Math.round(percentage),
            });
          });

        results.sort((a, b) => {
          if (a.percentage === b.percentage) return 0;
          if (a.percentage > b.percentage) return -1;

          return 1;
        });
      }

      if (!savedResults) {
        store.dispatch("setSavedResults", {
          userChoicesString: JSON.stringify(user.value.choices),
          results,
        });
      }

      if (onlySelected) {
        results = results.filter((result) => {
          let partyId = -1;

          parties.forEach((party: any) => {
            if (JSON.stringify(party) === JSON.stringify(result.party))
              partyId = party.id;
          });

          return (
            partyId !== -1 &&
            (user.value.selectedParties as number[]).indexOf(partyId) !== -1
          );
        });
      }

      return results;
    }

    function slugify(string: string): string {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");

      return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, "-and-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
    }

    function shareLink(
      platform: "facebook" | "twitter" | "linkedin" | "whatsapp",
    ): string {
      let linkToModify: string = "";

      if (route.name === "statement") {
        if (dictionary.statement.share) {
          switch (platform) {
            case "facebook":
              linkToModify = dictionary.statement.share.facebookWithStatement;
              break;
            case "twitter":
              linkToModify = dictionary.statement.share.twitterWithStatement;
              break;
            case "linkedin":
              linkToModify = dictionary.statement.share.linkedinWithStatement;
              break;
            case "whatsapp":
              linkToModify = dictionary.statement.share.whatsappWithStatement;
              break;
          }
        }

        linkToModify = linkToModify.replace(
          "[shareStatementUrl]",
          `${window.location.host}?${slugify(dictionary.statement.tabTitle)}=${store.getters.statement().id + 1}`,
        );
        linkToModify = linkToModify.replace(
          "[statementTitle]",
          store.getters.statement().title,
        );
      } else {
        switch (platform) {
          case "facebook":
            linkToModify = dictionary.app.share.facebook;
            break;
          case "twitter":
            linkToModify = dictionary.app.share.twitter;
            break;
          case "linkedin":
            linkToModify = dictionary.app.share.linkedin;
            break;
          case "whatsapp":
            linkToModify = dictionary.app.share.whatsapp;
            break;
        }

        linkToModify = linkToModify.replace(
          "[appUrl]",
          `${window.location.host}`,
        );
      }

      return linkToModify;
    }

    function statementTitleToString(
      title: IStatement["title"] | IShootoutStatement["title"],
    ): string {
      if (typeof title === "string") return title;

      let stringTitle = "";

      title.forEach((titlePart) => {
        if (stringTitle) stringTitle += " ";

        if (typeof titlePart === "string") {
          stringTitle += titlePart;
        } else {
          stringTitle += titlePart.text;
        }
      });

      return stringTitle;
    }

    function setDocumentTitle() {
      let title = "";

      if (route.name === "statement" || route.name === "shootout-statement") {
        title = `${dictionary.app.title} | ${route.name === "shootout-statement" ? dictionary.shootoutStatement.tabTitle : dictionary.statement.tabTitle} ${route.params.id} | ${statementTitleToString((store.getters.statement(JSON.parse(route.params.id.toString())) as IStatement).title)}`;
      } else if (route.meta.title) {
        title = `${dictionary.app.title} | ${route.meta.title}`;
      } else {
        title = `${dictionary.app.title}`;
      }

      try {
        document.getElementsByTagName("title")[0].innerHTML = title
          .replace("<", "&lt;")
          .replace(">", "&gt;")
          .replace(" & ", " &amp; ");
      } catch (Exception) {
        // ignore
      }

      document.title = title;
    }
    function trackGAEvent(event: string) {
      // extra check to ensure _paq is defined
      if (typeof _paq !== "undefined") {
        if (
          process.env.VUE_APP_MATOMO_ID &&
          process.env.VUE_APP_MATOMO_URL &&
          _paq
        ) {
          let category = "vmEvent";
          if (config.settings.analytics.title) {
            category = `${config.settings.analytics.title}`;
          }
          if (config.settings.analytics.matomoEventCategory) {
            category = `${config.settings.analytics.matomoEventCategory}`;
          }
          _paq.push(["trackEvent", category, event, location.href]);
        }
      }

      // extra check to ensure ga is enabled
      if (typeof gtag !== "undefined") {
        if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID && gtag) {
          let category = "vmEvent";
          if (config.settings.analytics.title) {
            category = `${config.settings.analytics.title}`;
          }
          if (config.settings.analytics.gaEventCategory) {
            category = `${config.settings.analytics.gaEventCategory}`;
          }

          gtag("event", event, {
            event_category: category,
            event_label: location.href,
          });
        }
      }
    }

    return {
      config,
      dictionary,
      votematch,
      parties,
      rawStatements,
      rawShootoutStatements,
      partyNameForChoicesOverviewTitle,
      updateCounter,
      store,
      route,
      router,
      statements,
      shootoutStatements,
      user,
      maxScore,
      isEmbedded,
      amountSkipped,

      setDocumentTitle,
      slugify,
      statementTitleToString,
      shareLink,
      trackGAEvent,
      results,
    };
  },
};
