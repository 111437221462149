<template>
  <nav
    class="app__nav"
    :class="{
      'app__nav--hidden': hideNav,
      'app__nav--choices-overview': route.name === 'choices-overview',
      'with-ad': config.settings.showAd,
    }"
  >
    <div class="app__nav-left">
      <button
        class="button button--back"
        :class="{ 'button--disabled': disableNavButtons }"
        @click="back"
        :tabindex="disableNavButtons ? '-1' : '0'"
        :aria-label="dictionary.app.accessibility.back"
      ></button>
      <div class="app__nav-logo">
        <img
          :alt="dictionary.app.logoAlt"
          :src="'gfx/img/' + config.style.logo"
        />
      </div>
    </div>
    <div class="app__nav-progress">
      <span
        class="app__nav-progress-indicator"
        :style="'width: ' + indicatorWidth + '%'"
      ></span>
    </div>
    <div class="app__nav-right">
      <RSInject v-if="config.settings.showReadSpeaker" />
      <div
        class="app__nav-counter"
        :class="{
          'app__nav-counter--hidden':
            route.name === 'results' || route.name === 'choices-overview',
        }"
      >
        <strong
          >{{
            route.name === "start"
              ? "1"
              : route.name === "statement" ||
                  route.name === "shootout-statement"
                ? route.params.id
                : Object.values(statements).length
          }}/</strong
        >{{
          route.name === "shootout-statement"
            ? Object.values(shootoutStatements).length
            : Object.values(statements).length
        }}
      </div>
      <button
        class="app__nav-share-button button button--share"
        :class="{
          'button--disabled': disableNavButtons,
          'app__nav-share-button--hidden': route.name === 'results',
        }"
        :aria-label="
          dictionary.app.accessibility.shareButtonName +
          (shareModalOpen
            ? ', ' + dictionary.app.accessibility.opened
            : ', ' + dictionary.app.accessibility.closed)
        "
        @click="shareModalOpen = !shareModalOpen"
        :tabindex="disableNavButtons ? '-1' : '0'"
        v-if="config.settings.showShareMenu"
      ></button>
      <a
        :href="config.style.clientLogoUrl"
        target="_blank"
        rel="nofollow"
        class="app__nav-client-logo"
      >
        <img
          :alt="dictionary.app.clientLogoAlt"
          :src="'gfx/img/' + config.style.clientLogoBlack"
        />
      </a>
      <div
        class="share__modal"
        v-click-away="() => (shareModalOpen = false)"
        v-if="shareModalOpen"
      >
        <h3 class="share__title">{{ shareTitle }}</h3>
        <span class="share__subtitle">{{ shareSubtitle }}</span>
        <div class="share__socials" v-if="showShareButtons">
          <a
            class="share__social-button share__social-button--facebook"
            :aria-label="dictionary.app.share.accessibility.facebookButtonLabel"
            tabindex="0"
            :href="shareLink('facebook')"
            target="_blank"
            v-if="
              route.name === 'statement'
                ? dictionary.statement.share?.facebookWithStatement
                : dictionary.app.share.facebook
            "
          ></a>
          <a
            class="share__social-button share__social-button--twitter"
            :aria-label="dictionary.app.share.accessibility.twitterButtonLabel"
            tabindex="0"
            :href="shareLink('twitter')"
            target="_blank"
            v-if="
              route.name === 'statement'
                ? dictionary.statement.share?.twitterWithStatement
                : dictionary.app.share.twitter
            "
          ></a>
          <a
            class="share__social-button share__social-button--linkedin"
            :aria-label="dictionary.app.share.accessibility.linkedinButtonLabel"
            tabindex="0"
            :href="shareLink('linkedin')"
            target="_blank"
            v-if="
              route.name === 'statement'
                ? dictionary.statement.share?.linkedinWithStatement
                : dictionary.app.share.linkedin
            "
          ></a>
          <a
            class="share__social-button share__social-button--whatsapp"
            :aria-label="dictionary.app.share.accessibility.whatsappButtonLabel"
            tabindex="0"
            :href="shareLink('whatsapp')"
            target="_blank"
            v-if="
              route.name === 'statement'
                ? dictionary.statement.share?.whatsappWithStatement
                : dictionary.app.share.whatsapp
            "
          ></a>
        </div>
        <div class="share__embed">
          <div class="share__embed-code">
            {{ embedCode }}
          </div>
          <div class="share__embed-button-wrapper">
            <button
              class="share__embed-button"
              :aria-label="dictionary.app.share.copyEmbedCode"
              tabindex="0"
              @click="copyEmbedCode"
            >
              <span class="overlay"></span>
              {{ dictionary.app.share.copyEmbedCode }}
            </button>
            <div
              class="share__embed-message share__embed-message--succes"
              v-if="shareSuccess"
            >
              {{ dictionary.app.share.copyEmbedCodeSucces }}
            </div>
            <div
              class="share__embed-message share__embed-message--fail"
              v-if="shareFail"
            >
              {{ dictionary.app.share.copyEmbedCodeFail }}
            </div>
          </div>
        </div>
        <button
          class="share__close"
          :aria-label="dictionary.app.closeModal"
          tabindex="0"
          @click="shareModalOpen = false"
        >
          {{ dictionary.app.closeModal }}
        </button>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import GlobalMixins from "@/mixins";
import { IStatement } from "../interfaces";
import useClipboard from "vue-clipboard3";
import { Ref, ref, computed, watch } from "vue";
import RSInject from "@/modules/rsinject/components/RSInject.vue";

export default {
  components: {
    RSInject,
  },
  setup() {
    const { toClipboard } = useClipboard();
    const {
      config,
      dictionary,
      route,
      router,
      store,
      statements,
      shootoutStatements,
      slugify,
      statementTitleToString,
      shareLink,
    } = GlobalMixins.setup();
    const shareModalOpen: Ref<boolean> = ref(false);
    const shareSuccess: Ref<boolean> = ref(false);
    const shareFail: Ref<boolean> = ref(false);

    const indicatorWidth = computed(function (): number {
      if (hideNav.value) return 0;
      if (route.name === "statement") {
        return (
          (100 / (statements.value.length + 3)) *
          JSON.parse(route.params.id.toString())
        );
      } else if (route.name === "shootout-statement") {
        return (
          (100 / shootoutStatements.value.length) *
          JSON.parse(route.params.id.toString())
        );
      } else if (route.name === "select-statements") {
        return (
          (100 / (statements.value.length + 3)) *
          (statements.value.length +
            (store.getters.showAnalyticsQuestion ? 1 : 1.5))
        );
      } else if (route.name === "select-parties") {
        return store.getters.showAnalyticsQuestion
          ? (100 / (statements.value.length + 3)) *
              (statements.value.length + 2)
          : 100;
      } else if (
        route.name === "select-analytics" ||
        route.name === "results" ||
        route.name === "choices-overview"
      ) {
        return 100;
      }

      return 0;
    });

    const disableNavButtons = computed(function (): boolean {
      if (hideNav.value) return true;
      if (
        route.name === "statement" &&
        route.params.id === "1" &&
        config.settings.skipStart
      )
        return true;

      return false;
    });

    const embedCode = computed(function (): string {
      if (route.name === "statement") {
        return `<iframe title="${dictionary.app.title}" width="1080" height="810" src="${window.location.protocol}//${window.location.host}?${slugify(dictionary.statement.tabTitle)}=${route.params.id}"></iframe>`;
      } else {
        return `<iframe title="${dictionary.app.title}" width="1080" height="810" src="${window.location.protocol}//${window.location.host}"></iframe>`;
      }
    });

    const hideNav = computed(function (): boolean {
      if (route.name === "start") return true;
      if (route.name === "pageNotFound") return true;

      return false;
    });

    const showShareButtons = computed(function (): boolean {
      if (route.name === "statement") {
        if (!dictionary.statement.share) return false;

        return dictionary.statement.share.facebookWithStatement ||
          dictionary.statement.share.twitterWithStatement ||
          dictionary.statement.share.linkedinWithStatement ||
          dictionary.statement.share.whatsappWithStatement
          ? true
          : false;
      } else {
        if (!dictionary.app.share) return false;

        return dictionary.app.share.facebook ||
          dictionary.app.share.twitter ||
          dictionary.app.share.linkedin ||
          dictionary.app.share.whatsapp
          ? true
          : false;
      }
    });

    const shareTitle = computed(function (): string {
      if (
        route.name === "statement" &&
        dictionary.statement.share &&
        dictionary.statement.share.title
      ) {
        return dictionary.statement.share.title;
      } else if (dictionary.app.share.title) {
        return dictionary.app.share.title;
      } else {
        return "";
      }
    });

    const shareSubtitle = computed(function (): string {
      if (
        route.name === "statement" &&
        dictionary.statement.share &&
        dictionary.statement.share.subtitle
      ) {
        return dictionary.statement.share.subtitle;
      } else if (dictionary.app.share.subtitle) {
        return dictionary.app.share.subtitle;
      } else {
        return "";
      }
    });

    watch(shareModalOpen, () => {
      if (!shareModalOpen.value) {
        shareSuccess.value = false;
        shareFail.value = false;
      }
    });
    watch(route, () => {
      shareModalOpen.value = false;
    });
    function back() {
      shareModalOpen.value = false;

      if (route.name === "statement" || route.name === "shootout-statement") {
        if (route.params.id === "1") {
          if (route.name === "shootout-statement") {
            router.push({
              name: "results",
            });
          } else {
            if (!config.settings.skipStart) {
              router.push({
                name: "start",
              });
            }
          }
        } else {
          router.push({
            name:
              route.name === "shootout-statement"
                ? "shootout-statement"
                : "statement",
            params: {
              id: JSON.stringify(JSON.parse(route.params.id.toString()) - 1),
              title: slugify(
                statementTitleToString(
                  store.getters.statement(
                    JSON.parse(route.params.id.toString()) - 1,
                  ).title,
                ),
              ),
            },
          });
        }
      } else if (route.name === "select-statements") {
        toLastStatement();
      } else if (route.name === "select-parties") {
        if (config.settings.maxSelectedStatements) {
          router.push({ name: "select-statements" });
        } else {
          toLastStatement();
        }
      } else if (route.name === "select-analytics") {
        router.push({ name: "select-parties" });
      } else if (route.name === "results") {
        router.push({
          name: store.getters.showAnalyticsQuestion
            ? "select-analytics"
            : "select-parties",
        });
      } else if (route.name === "choices-overview") {
        router.push({ name: "results" });
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function next() {
      shareModalOpen.value = false;
    }
    function copyEmbedCode() {
      toClipboard(embedCode.value)
        .then(() => {
          shareModalOpen.value = true;
          shareSuccess.value = true;
        })
        .catch(() => {
          shareModalOpen.value = true;
          shareFail.value = true;
        });
    }
    function toLastStatement(): void {
      const statementsLocal: IStatement[] = statements.value;
      router.push({
        name: "statement",
        params: {
          id: JSON.stringify(statementsLocal.length),
          title: slugify(
            statementTitleToString(
              (store.getters.statement(statementsLocal.length) as IStatement)
                .title,
            ),
          ),
        },
      });
    }

    // This used to be in the 'created'  function
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        shareModalOpen.value = false;
      }
    });
    ////

    return {
      dictionary,
      shareSuccess,
      copyEmbedCode,
      config,
      hideNav,
      route,
      back,
      disableNavButtons,
      indicatorWidth,
      shareModalOpen,
      shareFail,
      embedCode,
      showShareButtons,
      shareSubtitle,
      shareTitle,
      statements,
      shootoutStatements,

      shareLink,
    };
  },
};
</script>

<style lang="less">
@import (reference) "../less/main";
@import (reference) "../../public/less/custom";

.app__nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 67px;
  padding: 19px 32px 0;
  transition: opacity 0.15s 0.15s;

  @media @mobile {
    padding: 19px 16px 0;
  }

  &--hidden {
    pointer-events: none;
    transition-delay: 0s;
    opacity: 0;
  }

  &--choices-overview {
    @media @mobile {
      pointer-events: none;

      .app__nav-left {
        opacity: 0;
      }
    }

    &.with-ad {
      @media @with-ad-mobile {
        pointer-events: none;

        .app__nav-left {
          opacity: 0;
        }
      }
    }
  }

  &.with-ad {
    width: ~"calc(100% - 300px)";

    @media @mobile {
      top: 116px;
      width: 100%;
    }

    @media @with-ad-mobile {
      padding: 19px 16px 0;
    }
  }
}

.app__nav-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 7px;
}

.app__nav-progress-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 7px;
  background: @primary;
  transition-duration: 0.3s;
}

.app__nav-left,
.app__nav-right {
  display: flex;
  align-items: center;
}

.app__nav-logo {
  width: 122px;
  margin-left: 24px;

  @media @mobile {
    width: 87px;
    margin-left: 16px;
  }

  .with-ad & {
    @media @with-ad-mobile {
      width: 87px;
      margin-left: 16px;
    }
  }
}

.app__nav-counter {
  margin-right: 16px;
  border: 1px solid @grey-neutral;
  border-radius: 80px;
  padding: 8px 12px;
  transition: opacity 0.15s 0.15s;

  @media @mobile {
    margin-right: 0;

    & + .app__nav-share-button {
      margin-left: 16px;
    }
  }

  .with-ad & {
    @media @with-ad-mobile {
      margin-right: 0;

      & + .app__nav-share-button {
        margin-left: 16px;
      }
    }
  }

  &--hidden {
    opacity: 0;
    transition: opacity 0.15s;
  }
}

.app__nav-share-button {
  margin-right: 37px;
  transition:
    all 0.15s,
    opacity 0.15s 0.15s !important;

  @media @mobile {
    margin-right: 0;
  }

  .with-ad & {
    @media @with-ad-mobile {
      margin-right: 0;
    }
  }

  &--hidden {
    opacity: 0;
    transition: all 0.15s !important;
  }
}

.app__nav-client-logo {
  width: 83px;

  @media @mobile {
    display: none;
  }

  .with-ad & {
    @media @with-ad-mobile {
      display: none;
    }
  }
}

.app__nav-right {
  position: relative;
}
</style>
