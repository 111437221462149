<template>
  <template v-if="loaded">
    <template v-if="enabled">
      <div
        id="rsi-player"
        class="rs_addtools rs_splitbutton rs_preserve rs_skip rs_exp"
      ></div>
    </template>
    <template v-else>
      <a
        class="rsi-enable-link"
        @click="enable"
        :title="dictionary.link_enable"
        >{{ dictionary.link_enable }}</a
      >
    </template>
  </template>
  <template v-else>
    <a class="rsi-load-link" @click="load" :title="dictionary.link_load">{{
      dictionary.link_load
    }}</a>
  </template>

  <transition name="fade">
    <app-error
      @close="closeErrorModal"
      v-if="errorVisible"
      :title="dictionary.error_title"
      :text="dictionary.error_text"
      :buttonLabel="dictionary.error_close"
    />
  </transition>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { watch, ref, computed, nextTick } from "vue";
import AppError from "@/components/AppError.vue";
import RSInjectConfig from "../config.json";

export default {
  components: {
    AppError,
  },

  setup() {
    const store = useStore();

    const loaded = computed(() => store.getters["rsinject/loaded"]);
    const error = computed(() => store.getters["rsinject/error"]);
    const enabled = computed(() => store.getters["rsinject/enabled"]);
    const errorVisible = ref(false);

    watch(enabled, (value) => {
      if (value) {
        nextTick(() => {
          window.rspkr && window.rspkr.tools.toggler.add();
        });
      }
    });
    watch(error, (value) => {
      errorVisible.value = value;
    });

    function load() {
      store.dispatch("rsinject/load");
    }
    function enable() {
      store.dispatch("rsinject/enable");
    }
    function disable() {
      store.dispatch("rsinject/disable");
    }

    function closeErrorModal() {
      errorVisible.value = false;
    }

    return {
      loaded,
      errorVisible,
      enabled,
      load,
      enable,
      disable,
      closeErrorModal,
      dictionary: RSInjectConfig.dictionary,
    };
  },
};
</script>

<style lang="less">
@import (reference) "@/less/main";
@import "../less/skin.less";
@import "../less/rsinject.less";
@import "../less/votematch.less";
</style>

<style lang="less" scoped>
@import (reference) "@/less/main";
@import (reference) "../../../../public/less/custom";

.rsi-enable-link,
.rsi-load-link {
  margin-right: 8px;
}
</style>
