import { MutationTree } from "vuex";
import { State } from ".";
import { IUserChoice, IUserShootoutChoice } from "@/interfaces";

const mutations: MutationTree<any> = {
  setFirstStatementDefaultIndex(state: State, index: number) {
    state.firstStatementDefaultIndex = index;
  },
  setUserChoices(state: State, userchoices: IUserChoice[]) {
    state.user.choices = userchoices;
  },
  setUserShootoutChoices(
    state: State,
    userShootoutChoices: IUserShootoutChoice[],
  ) {
    state.user.shootoutChoices = userShootoutChoices;
  },
  increaseTimeSpendMakingChoices(state: State) {
    state.timeSpendMakingChoices++;
  },
  setPriorityStatements(state: State, priorityStatements: number[] | false) {
    state.user.priorityStatements = priorityStatements;
  },
  setSelectedParties(state: State, selectedParties: number[] | false) {
    state.user.selectedParties = selectedParties;
  },
  skippedStart(state: State) {
    state.skippedStart = true;
  },
  seenPrivacyStatement(state: State) {
    state.seenPrivacyStatement = true;
  },
  setUserAnalytics(
    state: State,
    analytics: { sendAllChoices: boolean; sendForAnalysis: boolean },
  ) {
    state.user.analytics = analytics;
  },
  setUserToken(state: State, token: string) {
    state.user.analyticsToken = token;
  },
  setDirection(state: State, direction: "left" | "right") {
    state.direction = direction;
  },
  setShootoutParties(state: State, parties: false | [number, number]) {
    state.user.shootoutParties = parties;
  },
  setShootoutResultsVisibility(state: State, payload: boolean) {
    state.showShootoutResults = payload;
  },
  seenAnalyticsQuestion(state: State) {
    state.seenAnalyticsQuestion = true;
  },
  setSavedResults(state: State, payload) {
    state.savedResults = payload;
  },
};

export default mutations;
