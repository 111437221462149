import { createStore } from "vuex";

import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import { IUserChoice, IUserShootoutChoice } from "@/interfaces";

export interface State {
  firstStatementDefaultIndex: number | false;
  user: {
    choices: IUserChoice[];
    shootoutChoices: IUserShootoutChoice[];
    priorityStatements: number[] | false;
    selectedParties: number[] | false;
    shootoutParties: false | [number, number];
    results: [];
    analytics: {
      sendAllChoices: boolean;
      sendForAnalysis: boolean;
    };
    analyticsToken: string;
  };
  timeSpendMakingChoices: number;
  skippedStart: boolean;
  seenPrivacyStatement: boolean;
  seenAnalyticsQuestion: boolean;
  direction: "left" | "right";
  showShootoutResults: boolean;
  savedResults:
    | {
        userChoicesString: string;
        results: [];
      }
    | false;
}

const state: State = {
  firstStatementDefaultIndex: false,
  user: {
    choices: [],
    shootoutChoices: [],
    priorityStatements: false,
    selectedParties: false,
    shootoutParties: false,
    results: [],
    analytics: {
      sendAllChoices: false,
      sendForAnalysis: false,
    },
    analyticsToken: "",
  },
  timeSpendMakingChoices: 0,
  skippedStart: false,
  seenPrivacyStatement: false,
  seenAnalyticsQuestion: false,
  direction: "left",
  showShootoutResults: false,
  savedResults: false,
};

export default createStore({
  state,
  mutations,
  getters,
  actions,
});
