import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5951480f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "rsi-player",
  class: "rs_addtools rs_splitbutton rs_preserve rs_skip rs_exp"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_error = _resolveComponent("app-error")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($setup.enabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "rsi-enable-link",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.enable && $setup.enable(...args))),
                title: $setup.dictionary.link_enable
              }, _toDisplayString($setup.dictionary.link_enable), 9, _hoisted_2))
        ], 64))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          class: "rsi-load-link",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.load && $setup.load(...args))),
          title: $setup.dictionary.link_load
        }, _toDisplayString($setup.dictionary.link_load), 9, _hoisted_3)),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($setup.errorVisible)
          ? (_openBlock(), _createBlock(_component_app_error, {
              key: 0,
              onClose: $setup.closeErrorModal,
              title: $setup.dictionary.error_title,
              text: $setup.dictionary.error_text,
              buttonLabel: $setup.dictionary.error_close
            }, null, 8, ["onClose", "title", "text", "buttonLabel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}