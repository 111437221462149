<template>
  <div
    class="app"
    :class="{
      'with-ad': config.settings.showAd && route.name !== 'embed',
      'app--white': route.name !== 'start',
      'app--prevent-scrolling': preventScrolling,
    }"
  >
    <app-nav v-if="route.name !== 'embed'" />

    <router-view
      class="app__content"
      v-slot="slotProps"
      @preventScrolling="preventScrolling = true"
      @allowScrolling="preventScrolling = false"
      @openPrivacyModal="privacyModalOpen = true"
      :key="routeKey"
    >
      <transition mode="out-in" :name="contentAnimation">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
    <div
      class="app__ad"
      v-if="
        config.settings.showAd &&
        config.settings.desktopAdCode &&
        route.name !== 'embed'
      "
      v-html="config.settings.desktopAdCode"
    ></div>
    <div
      class="app__ad app__ad--mobile"
      v-if="
        config.settings.showAd &&
        config.settings.mobileAdCode &&
        route.name !== 'embed'
      "
      v-html="config.settings.mobileAdCode"
    ></div>
    <transition
      :name="
        config.settings.showAd
          ? 'slide-right-mobile-down-with-ad'
          : 'slide-right-mobile-down'
      "
    >
      <div
        class="privacy__wrapper"
        v-if="
          store.getters.showPrivacyStatement &&
          animateOnLoad &&
          route.name !== 'embed'
        "
      >
        <header class="privacy__header">
          <span class="privacy__header-title">{{
            dictionary.app.privacy.title
          }}</span>
          <button
            class="privacy__close"
            :aria-label="dictionary.app.closeModal"
            tabindex="0"
            @click="closePrivacy()"
          >
            {{ dictionary.app.closeModal }}
          </button>
        </header>
        <div
          class="privacy__text"
          :aria-label="
            dictionary.app.accessibility.privacy.text
              ? dictionary.app.accessibility.privacy.text
              : dictionary.app.privacy.text
          "
          v-html="dictionary.app.privacy.text"
        ></div>
        <button
          v-if="dictionary.app.privacy.modalText"
          class="privacy__button button button--white"
          @click="privacyModalOpen = true"
        >
          {{ dictionary.app.privacy.button }}
        </button>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="modal__wrapper"
        ref="modalWrapper"
        @click="closePrivacyModal"
        v-if="privacyModalOpen"
      >
        <div class="modal">
          <h3 class="modal__title">{{ dictionary.app.privacy.title }}</h3>
          <div
            class="modal__content text"
            :aria-label="
              dictionary.app.accessibility.privacy.modalText
                ? dictionary.app.accessibility.privacy.modalText
                : dictionary.app.privacy.modalText
            "
            v-html="dictionary.app.privacy.modalText"
          ></div>
          <button
            class="privacy__close privacy__close--black"
            ref="modalClose"
            :aria-label="dictionary.app.closeModal"
            tabindex="0"
            @click="privacyModalOpen = false"
          >
            {{ dictionary.app.closeModal }}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import GlobalMixins from "@/mixins";
import AppNav from "@/components/AppNav.vue";
import { watch } from "vue";

import { ref, onMounted, computed, Ref } from "vue";

export default {
  components: {
    AppNav,
  },
  name: "App",
  setup() {
    const { config, dictionary, route, store, setDocumentTitle } =
      GlobalMixins.setup();
    const resizeId: Ref<null | number> = ref(null);
    const preventScrolling: Ref<boolean> = ref(false);
    const animateOnLoad: Ref<boolean> = ref(false);
    const privacyModalOpen: Ref<boolean> = ref(false);

    const modalClose = ref(null);
    const modalWrapper = ref(null);

    const routeKey = computed(function () {
      return route.name === "results" || route.name === "choices-overview"
        ? "results"
        : route.path;
    });

    const contentAnimation = computed(function () {
      if (animateOnLoad.value) {
        if (config.settings.showAd) {
          return `content-slide-with-ad-${store.getters.direction}`;
        } else {
          return `content-slide-${store.getters.direction}`;
        }
      } else {
        return "fade";
      }
    });

    function calculateHeight(): void {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`,
      );
    }
    function closePrivacy(): void {
      store.dispatch("seenPrivacyStatement");
    }
    function closePrivacyModal(e: any): void {
      if (e.target.classList.contains("modal__wrapper")) {
        privacyModalOpen.value = false;
      }
    }

    watch(route, () => {
      setDocumentTitle();
      if (route.name === "start" && store.getters.showPrivacyStatement)
        closePrivacy();
    });

    // 	used to be in created()
    calculateHeight();

    window.addEventListener("resize", () => {
      if (resizeId.value) {
        clearTimeout(resizeId.value);
      }
      resizeId.value = setTimeout(calculateHeight, 500);
    });
    window.addEventListener("popstate", () => {
      setDocumentTitle();
    });

    document.addEventListener("keyup", (e: any) => {
      if (e.code === "Escape") privacyModalOpen.value = false;
      if (e.code === "Tab" && privacyModalOpen) {
        e.preventDefault();

        if (modalWrapper.value && modalClose.value) {
          if (
            !(modalWrapper.value as unknown as HTMLElement).contains(
              document.activeElement,
            )
          )
            (modalClose.value as unknown as HTMLElement).focus();
        }
      }
    });

    setDocumentTitle();
    if (config.settings.showAd) {
      document.body.classList.add("with-ad");
    }
    ////

    onMounted(() => {
      setTimeout(() => {
        animateOnLoad.value = true;
      }, 150);
    });

    return {
      routeKey: routeKey,
      contentAnimation: contentAnimation,
      preventScrolling: preventScrolling,
      privacyModalOpen: privacyModalOpen,
      animateOnLoad,
      dictionary,
      config,
      closePrivacy,
      closePrivacyModal,
      resizeId,
      store,
      route,
      modalClose,
      modalWrapper,
    };
  },
};
</script>

<style lang="less">
@import "./less/main";
@import "../public/less/custom";

.app {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background 0.3s;

  @media @mobile {
    min-height: ~"calc(var(--vh, 1vh) * 100)";
    flex-direction: column-reverse;
  }

  &--white {
    background: @general-white;
  }

  &--prevent-scrolling {
    height: 100vh;
    overflow: hidden;

    @media @mobile {
      height: ~"calc(var(--vh, 1vh) * 100)";
    }

    &.with-ad {
      .app__content {
        @media @mobile {
          height: ~"calc((var(--vh, 1vh) * 100) - 116px)";
          min-height: 0;
        }
      }
    }
  }
}

.app__content {
  width: 100%;
  min-height: 100vh;

  @media @mobile {
    min-height: ~"calc(var(--vh, 1vh) * 100)";
  }

  .with-ad & {
    width: ~"calc(100% - 300px)";

    @media @with-ad-mobile {
      min-height: ~"calc(var(--vh, 1vh) * 100)";
    }

    @media @mobile {
      width: 100%;
      min-height: ~"calc((var(--vh, 1vh) * 100) - 116px)";
    }
  }
}

.app__ad {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 300px;
  height: 100vh;
  background: @grey-neutral;

  @media @mobile {
    display: none;
  }

  &--mobile {
    display: none;

    @media @mobile {
      position: static;
      display: flex;
      width: 100%;
      height: 116px;
      min-height: 0;
    }
  }
}

.privacy__wrapper {
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 20;
  width: 100%;
  max-width: 530px;
  border-radius: 8px;
  background: @general-black;
  color: @general-white;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.1),
    0px 8px 24px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  @media @mobile {
    right: 0;
    bottom: 0;
    border-radius: 0;
    max-width: none;
  }

  .with-ad & {
    right: 320px;

    @media @with-ad-mobile {
      right: 300px;
      bottom: 0;
      border-radius: 0;
      width: ~"calc(100% - 300px)";
      max-width: none;
    }

    @media @mobile {
      right: 0;
      width: 100%;
    }
  }
}

.privacy__header {
  padding: 16px 96px 12px 16px;
  background: @body;
}

.privacy__header-title {
  font-weight: @bold;
  font-size: 16px;

  @media @mobile {
    font-size: 14px;
  }

  .with-ad & {
    @media @with-ad-mobile {
      font-size: 14px;
    }
  }
}

.privacy__close {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  padding: 4px 4px 4px 8px;
  font-size: 12px;
  color: @general-white;
  cursor: pointer;
  transition: all 0.15s;

  @media @mobile {
    font-size: 10px;
  }

  .with-ad & {
    @media @with-ad-mobile {
      font-size: 10px;
    }
  }

  .focus-border(~"::before", @radius: 24);

  &::after {
    content: "";
    width: 24px;
    height: 24px;
    margin-left: 8px;
    @white: replace(@general-white, "#", "%23");
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11.5' stroke='@{white}'/%3E%3Cpath d='M16.616 8.32a.676.676 0 00-.002-.955l.021.02a.676.676 0 00-.955 0l-3.532 3.532a.208.208 0 01-.296 0L8.32 7.384a.676.676 0 00-.954.002l.02-.021a.676.676 0 000 .955l3.532 3.532a.208.208 0 010 .296L7.384 15.68a.676.676 0 00.002.955l-.021-.02a.676.676 0 00.954 0l3.533-3.532a.208.208 0 01.296 0l3.532 3.533a.675.675 0 00.955-.002l-.021.021a.676.676 0 00.002-.955l-3.533-3.532a.208.208 0 010-.296l3.533-3.532z' fill='@{white}'/%3E%3C/svg%3E")
      center / contain no-repeat;
  }

  &:hover {
    &:focus {
      &::before {
        border-color: darken(@primary, 10);
      }
    }
  }

  &--black {
    color: @general-black;

    &::after {
      @black: replace(@general-black, "#", "%23");
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11.5' stroke='@{black}'/%3E%3Cpath d='M16.616 8.32a.676.676 0 00-.002-.955l.021.02a.676.676 0 00-.955 0l-3.532 3.532a.208.208 0 01-.296 0L8.32 7.384a.676.676 0 00-.954.002l.02-.021a.676.676 0 000 .955l3.532 3.532a.208.208 0 010 .296L7.384 15.68a.676.676 0 00.002.955l-.021-.02a.676.676 0 00.954 0l3.533-3.532a.208.208 0 01.296 0l3.532 3.533a.675.675 0 00.955-.002l-.021.021a.676.676 0 00.002-.955l-3.533-3.532a.208.208 0 010-.296l3.533-3.532z' fill='@{black}'/%3E%3C/svg%3E")
        center / contain no-repeat;
    }
  }
}

.privacy__text {
  padding: 16px;
  font-size: 12px;

  a {
    color: @general-white;
  }
}

.privacy__button {
  padding: 16px;
  font-size: 80%;
  margin: 0 0 16px 16px;
}

.modal__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .color-o(@general-black, 0.5);
  background: @color-o;

  .with-ad & {
    width: ~"calc(100% - 300px)";

    @media @mobile {
      width: 100%;
    }
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: ~"calc(100% - 64px)";
  max-width: 800px;
  max-height: ~"calc(100% - 64px)";
  border-radius: 24px;
  padding: 40px;
  background: @general-white;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  h4 {
    font-weight: 700;
  }

  ul {
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 15px;
  }

  ul,
  li {
    list-style-type: disc;
  }

  @media @mobile {
    padding: 24px 16px;
  }

  @media @mobile-s {
    width: ~"calc(100% - 32px)";
    padding: 16px;
  }

  .with-ad & {
    @media @with-ad-mobile {
      padding: 24px 16px;
    }

    @media @with-ad-mobile-s {
      width: ~"calc(100% - 32px)";
      padding: 16px;
    }
  }
}

.modal__title {
  margin-bottom: 12px;
  font-weight: @bold;
  font-size: 32px;
  color: @general-black;
  line-height: 100%;

  @media @mobile {
    font-size: 20px;
    line-height: 24px;
  }

  .with-ad & {
    @media @with-ad-mobile {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>
