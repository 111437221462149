import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import mixins from "./mixins";
import VueClickAway from "vue3-click-away";

const { config } = mixins.setup();

export const StemWijzerHTTP = axios.create({
  baseURL: config.settings.analytics ? config.settings.analytics.baseUrl : "",
});

// Enable RSInject module
import rsinject from "./modules/rsinject/module";
if (config.settings.showReadSpeaker) {
  store.registerModule("rsinject", rsinject.store);
  store.dispatch("rsinject/init", router, { root: true });
}

const app = createApp(App);
app.use(VueClickAway);
app.use(router);
app.use(store);
app.mount("#app");
