import { GetterTree } from "vuex";
import { State } from ".";
import { IPartyStatement, IStatement, IShootoutStatement } from "@/interfaces";
import router from "@/router";
import GlobalMixins from "@/mixins";

const { parties, rawStatements, dictionary, config, rawShootoutStatements } =
  GlobalMixins.setup();

const getters: GetterTree<any, any> = {
  firstStatementDefaultIndex(state: State) {
    return state.firstStatementDefaultIndex;
  },
  statements(state: State): IStatement[] {
    const statements = rawStatements;

    if (state.firstStatementDefaultIndex !== false) {
      const firstStatement = statements.splice(
        state.firstStatementDefaultIndex,
        1,
      );

      statements.unshift(firstStatement[0]);
    }

    return statements;
  },
  statement:
    (state: State, getters: any) =>
    (statementIndex?: number): IStatement | IShootoutStatement => {
      let statement: any = false;
      const statements =
        router.currentRoute.value.name === "shootout-statement"
          ? getters.shootoutStatements()
          : getters.statements;

      if (statementIndex && statementIndex <= statements.length) {
        statement = statements[statementIndex - 1];
      } else if (
        router.currentRoute.value.name === "statement" ||
        router.currentRoute.value.name === "shootout-statement" ||
        router.currentRoute.value.name === "embed"
      ) {
        statement =
          statements[
            JSON.parse(router.currentRoute.value.params.id.toString()) - 1
          ];
      }

      if (statement) {
        const partiesLocal = parties
          .filter((party: any) => {
            if (!party.participates) return false;

            if (router.currentRoute.value.name === "shootout-statement") {
              return (
                (state.user.shootoutParties as [number, number]).indexOf(
                  party.id,
                ) !== -1
              );
            }

            return true;
          })
          .map((party: any) => {
            const partyStatementPosition = (
              router.currentRoute.value.name === "shootout-statement"
                ? (party.shootoutStatements as IPartyStatement[])
                : (party.statements as IPartyStatement[])
            ).filter((partyStatement) => {
              return statement.id === partyStatement.id;
            })[0];

            return {
              name: party.name,
              fullName: party.fullName,
              logoIndex: party.logoIndex,
              statement: partyStatementPosition,
            };
          });

        statement.parties = {
          agree: partiesLocal.filter(
            (party: any) => party.statement.position === "agree",
          ),
          neither: partiesLocal.filter(
            (party: any) => party.statement.position === "neither",
          ),
          disagree: partiesLocal.filter(
            (party: any) => party.statement.position === "disagree",
          ),
        };
      }

      return statement;
    },
  user(state: State): State["user"] {
    return state.user;
  },
  timeSpendMakingChoices(state: State): number {
    return state.timeSpendMakingChoices;
  },
  showAnalyticsQuestion(state: State): boolean {
    if (state.seenAnalyticsQuestion) return false;
    if (!config.settings.minimumTimeSpent || !config.settings.analytics.enabled) return false;
    if (
      !dictionary.selectAnalytics.options ||
      !(
        dictionary.selectAnalytics.options.sendAllChoices ||
        dictionary.selectAnalytics.options.sendForAnalysis
      )
    )
      return false;
    if (
      dictionary.selectAnalytics.options.sendAllChoices &&
      (!config.settings.analytics ||
        typeof config.settings.analytics.votematchID !== "number")
    )
      return false;
    if (
      dictionary.selectAnalytics.options.sendForAnalysis &&
      (!config.settings.analytics ||
        typeof config.settings.analytics.votematchID !== "number" ||
        !config.settings.analytics.templateVersion)
    )
      return false;

    return true;
  },
  showRoadrunnerError(state: State) {
    if (!config.settings.minimumTimeSpent) return false;

    return !(
      state.timeSpendMakingChoices >
      config.settings.minimumTimeSpent * 10
    );
  },
  showPrivacyStatement(state: State): boolean {
    return state.skippedStart && !state.seenPrivacyStatement;
  },
  direction(state: State): "left" | "right" {
    return state.direction;
  },
  shootoutStatements:
    (state: State) =>
    (_shootoutParties?: [number, number]): IShootoutStatement[] => {
      let shootoutParties: [number, number];

      if (!_shootoutParties || _shootoutParties.length !== 2) {
        if (
          !state.user.shootoutParties ||
          state.user.shootoutParties.length !== 2
        )
          return [];

        shootoutParties = state.user.shootoutParties;
      } else {
        shootoutParties = _shootoutParties;
      }

      if (config.shootouts && config.shootouts.length > 0) {
        // find preconfigured shootout for specified parties
        const configuredShootout: any = config.shootouts.find(
          (shootout: any) => {
            return (
              shootout.parties.includes(shootoutParties[0]) &&
              shootout.parties.includes(shootoutParties[1])
            );
          },
        );
        // if preconfigured shootout exists, return statements
        if (configuredShootout) {
          const results: IShootoutStatement[] = [];
          rawShootoutStatements.forEach((statement: any) => {
            for (let i = 0; i < configuredShootout.statements.length; i++) {
              if (statement.id == configuredShootout.statements[i]) {
                results[i] = statement;
                break;
              }
            }
          });
          return results;
        }
      }

      const partiesLocal = parties.filter((party: any) => {
        return shootoutParties.indexOf(party.id) !== -1;
      });
      const closeStatements: IShootoutStatement[] = [];
      const BigDiffStatements: IShootoutStatement[] = [];

      rawShootoutStatements
        .filter((statement: any) => {
          const partiesPositions = partiesLocal.map((party: any) => {
            let partyOpinion: false | "agree" | "neither" | "disagree" = false;

            (party.shootoutStatements as IPartyStatement[]).forEach(
              (partyStatement) => {
                if (partyStatement.id === statement.id)
                  partyOpinion = partyStatement.position;
              },
            );

            return partyOpinion;
          });

          if (!partiesPositions[0] || !partiesPositions[1]) return false;
          if (partiesPositions[0] === partiesPositions[1]) return false;

          return true;
        })
        .forEach((statement: any) => {
          const partiesPositions: ("agree" | "neither" | "disagree")[] =
            partiesLocal.map((party: any) => {
              let partyOpinion: undefined | "agree" | "neither" | "disagree" =
                undefined;

              (party.shootoutStatements as IPartyStatement[]).forEach(
                (partyStatement) => {
                  if (partyStatement.id === statement.id)
                    partyOpinion = partyStatement.position;
                },
              );

              return partyOpinion as any;
            });

          if (
            partiesPositions[0] === "neither" ||
            partiesPositions[1] === "neither"
          ) {
            closeStatements.push(statement);
          } else {
            BigDiffStatements.push(statement);
          }
        });

      let selectionStatements: IShootoutStatement[] = BigDiffStatements.concat(
        closeStatements,
      ).slice(0, config.settings.shootoutStatementAmount);

      if (!(selectionStatements.length % 2)) {
        selectionStatements = selectionStatements.slice(
          0,
          selectionStatements.length - 1,
        );
      }
      if (
        config.settings.shootoutMinimumStatementAmount &&
        selectionStatements.length <
          config.settings.shootoutMinimumStatementAmount
      ) {
        return [];
      }

      return selectionStatements;
    },
  showShootoutResults(state: State): boolean {
    return state.showShootoutResults;
  },
  savedResults(state: State): any {
    return state.savedResults;
  },
};

export default getters;
